import { authReducer } from 'components/auth/store/slice';
import { combineReducers } from '@reduxjs/toolkit';
import { commentsReducer } from 'components/comments/store/slice';
import { mediaReducer } from 'components/media/store/slice';
import { postReducer } from 'components/post/store/slice';
import { searchReducer } from 'components/search/store/slice';
import { siteReducer } from 'components/site/store/slice';

export const rootReducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
  media: mediaReducer,
  post: postReducer,
  comments: commentsReducer,
  search: searchReducer,
});

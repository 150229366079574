import * as TK from 'components/auth/EmailVerificationForm/translations/constants';
import { Translations } from 'translations/types';

export const emailVerificationFormEnUsLocale: Translations = {
  [TK.EMAIL_VERIFICATION_FORM_TOKEN_PLACEHOLDER]: 'Enter code ex. 123456',
  [TK.EMAIL_VERIFICATION_FORM_EMAIL_SENT_TEXT]: 'We have just sent an email to “{{email}}” with a  6-digit code. Please copy that code and paste in the below field. Please note that the code expires in 10 minutes.',
  [TK.EMAIL_VERIFICATION_FORM_TOKEN_REQUIRED_ERROR_MESSAGE]: 'This field is required.',
  [TK.EMAIL_VERIFICATION_FORM_TOKEN_INCORRECT_ERROR_MESSAGE]: 'Please enter a valid OTP.',
  [TK.EMAIL_VERIFICATION_FORM_TOKEN_EXPIRED_OR_INVALID_ERROR_MESSAGE]: 'Your code has expired or is invalid. Please try again.',
  [TK.EMAIL_VERIFICATION_FORM_SUBMIT_BUTTON_LABEL]: 'Next',
  [TK.EMAIL_VERIFICATION_FORM_RESEND_CODE_LABEL]: 'Didn’t receive code?',
  [TK.EMAIL_VERIFICATION_FORM_RESEND_CODE_BUTTON_TEXT]: 'Resend code',
};

import { PostState } from 'components/post/store/types';
import { createDefaultAPIRequestState } from 'store/utils/create-default-api-request-state';
import {
  createPost,
  deletePost,
  getHomeFeedPosts,
  getMyPosts,
  getPost,
  getPostAllReactions,
  updatePost,
} from 'components/post/store/actions';
import { createSlice } from '@reduxjs/toolkit';
import {
  handleFulfilledRequestWithPagination,
  handlePendingRequestWithPagination,
  handleRejectedRequestWithPagination,
} from 'store/utils/pagination';

const initialState: PostState = {
  homeFeedPosts: createDefaultAPIRequestState(null, { withPagination: true }),
  myPosts: createDefaultAPIRequestState(null, { withPagination: true }),
  post: createDefaultAPIRequestState(null),
  createEditPost: createDefaultAPIRequestState(),
  deletePost: createDefaultAPIRequestState(),
  postReactions: createDefaultAPIRequestState(null),
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    resetPost: (state) => {
      state.post.data = null;
    },
    resetPostAllReactions: (state) => {
      state.postReactions.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyPosts.pending, (state, { meta }) => {
        handlePendingRequestWithPagination(state.myPosts, meta.arg);
      })
      .addCase(getMyPosts.fulfilled, (state, { payload, meta }) => {
        handleFulfilledRequestWithPagination(state.myPosts, payload, meta.arg);
      })
      .addCase(getMyPosts.rejected, (state, { meta, payload }) => {
        handleRejectedRequestWithPagination(state.myPosts, payload, meta.arg);
      })

      .addCase(getHomeFeedPosts.pending, (state, { meta }) => {
        handlePendingRequestWithPagination(state.homeFeedPosts, meta.arg);
      })
      .addCase(getHomeFeedPosts.fulfilled, (state, { payload, meta }) => {
        handleFulfilledRequestWithPagination(state.homeFeedPosts, payload, meta.arg);
      })
      .addCase(getHomeFeedPosts.rejected, (state, { meta, payload }) => {
        handleRejectedRequestWithPagination(state.homeFeedPosts, payload, meta.arg);
      })

      .addCase(createPost.pending, (state) => {
        state.createEditPost.loading = true;
        state.createEditPost.error = '';
      })
      .addCase(createPost.fulfilled, (state) => {
        state.createEditPost.loading = false;
      })
      .addCase(createPost.rejected, (state, { payload }) => {
        state.createEditPost.loading = false;
        state.createEditPost.error = payload as string;
      })

      .addCase(updatePost.pending, (state) => {
        state.createEditPost.loading = true;
        state.createEditPost.error = '';
      })
      .addCase(updatePost.fulfilled, (state) => {
        state.createEditPost.loading = false;
      })
      .addCase(updatePost.rejected, (state, { payload }) => {
        state.createEditPost.loading = false;
        state.createEditPost.error = payload as string;
      })

      .addCase(getPost.pending, (state) => {
        state.post.loading = true;
        state.post.error = '';
      })
      .addCase(getPost.fulfilled, (state, { payload }) => {
        state.post.data = payload;
        state.post.loading = false;
      })
      .addCase(getPost.rejected, (state, { payload }) => {
        state.post.loading = false;
        state.post.error = payload as string;
      })

      .addCase(deletePost.pending, (state) => {
        state.deletePost.loading = true;
        state.deletePost.error = '';
      })
      .addCase(deletePost.fulfilled, (state) => {
        state.deletePost.loading = false;
      })
      .addCase(deletePost.rejected, (state, { payload }) => {
        state.deletePost.loading = false;
        state.deletePost.error = payload as string;
      })

      .addCase(getPostAllReactions.pending, (state) => {
        state.postReactions.loading = true;
        state.postReactions.error = '';
      })
      .addCase(getPostAllReactions.fulfilled, (state, { payload }) => {
        state.postReactions.data = payload;
        state.postReactions.loading = false;
      })
      .addCase(getPostAllReactions.rejected, (state, { payload }) => {
        state.postReactions.loading = false;
        state.postReactions.error = payload as string;
      })

      .addDefaultCase((state) => state);
  },
});

export const { resetPost, resetPostAllReactions } = postSlice.actions;

export const postReducer = postSlice.reducer;

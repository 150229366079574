export enum AppRoutes {
  HOME = '/',
  MY_SITE = '/my-site',
  NEW_POST = '/new-post',
  POST = '/post/:id',
  EDIT_POST = '/post/:id/edit',
  SEARCH = '/search',
  SIGN_IN = '/login',
  SIGN_UP = '/register',
  EMAIL_VERIFICATION = '/verification',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  SITE_CREATION = '/site-creation',
}

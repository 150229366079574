import * as TK from 'components/auth/SignUpForm/translations/constants';
import { AppRoutes } from 'enums/app-routes.enum';
import { BASE_URL } from 'configuration/env';
import { RootState } from 'store';
import { StorageBuckets } from 'components/media/enums/storage-buckets.enum';
import { User as SupabaseUser } from '@supabase/supabase-js';
import { UpdateAvatarData } from 'components/site/store/actions';
import { User } from 'components/auth/store/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteImages } from 'components/media/store/actions';
import { selectUser } from 'components/auth/store/selectors';
import { supabase } from 'configuration/supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export interface SignInData {
  email: string;
  password: string;
}

export interface SignUpData extends SignInData {
  username: string;
}

export interface VerifyOtpData {
  email: string;
  token: string;
  username: string;
}

interface UploadAvatarResponse {
  error: {
    message: string;
  } | null;
  data?: User | null;
}

export const uploadAvatar = async (
  avatar: File,
  uid: string,
): Promise<UploadAvatarResponse> => {
  const avatarPath = `${uid}/${uuidv4()}.${avatar.name.split('.').pop() || ''}`;
  const { error } = await supabase.storage
    .from(StorageBuckets.AVATARS)
    .upload(avatarPath, avatar);

  if (error) {
    return { error };
  }

  return supabase
    .from('my_user')
    .update({ profile_image: avatarPath })
    .eq('uid', uid)
    .select()
    .single();
};

export const updateAvatar = createAsyncThunk(
  'auth/updateAvatar',
  async ({
    oldAvatar,
    avatar,
  }: UpdateAvatarData, { rejectWithValue, getState }) => {
    try {
      const user = selectUser(getState() as RootState);
      if (!user) {
        return rejectWithValue(null);
      }
      const { data, error } = await uploadAvatar(avatar, user.uid);
      if (error) {
        return rejectWithValue(error.message);
      }

      if (oldAvatar) {
        await deleteImages([oldAvatar], StorageBuckets.AVATARS);
      }

      return data as User;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase
      .from('my_user')
      .select()
      .single<User>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({
    email,
    password,
  }: SignInData, { rejectWithValue }) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    return data.user;
  },
);

const mapSupabaseUserToUser = (user: SupabaseUser): User => ({
  uid: user.id,
  email: user.email || '',
  username: user.user_metadata.username,
  sid: user.user_metadata.sid,
});

export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({
    email,
    password,
    username,
  }: SignUpData, { rejectWithValue }) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          username,
        },
      },
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    if (data.user?.identities?.length === 0) {
      return rejectWithValue(TK.SIGN_UP_FORM_EMAIL_EXISTS_ERROR_MESSAGE);
    }

    return data.user
      ? mapSupabaseUserToUser(data.user)
      : null;
  },
);

function sendWelcomeEmail(email: string, username: string) {
  return supabase.functions
    .invoke('send-welcome-email', {
      body: {
        email,
        username,
      },
    });
}

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({
    email,
    token,
    username,
  }: VerifyOtpData, { rejectWithValue }) => {
    const { data, error } = await supabase.auth.verifyOtp({
      type: 'signup',
      email,
      token,
    });

    if (data?.user) {
      sendWelcomeEmail(email, username);
    }

    if (error) {
      return rejectWithValue(error.message);
    }

    return data.user;
  },
);

export const sendResetPasswordEmail = createAsyncThunk(
  'auth/sendResetPasswordEmail',
  async (email: string, { rejectWithValue }) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${BASE_URL}${AppRoutes.RESET_PASSWORD}`,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (password: string, { rejectWithValue }) => {
    const { data, error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (_, { rejectWithValue }) => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      return rejectWithValue(error.message);
    }

    return null;
  },
);

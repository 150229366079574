import * as TK from 'components/site/SiteCreationForm/translations/constants';
import { Translations } from 'translations/types';

export const siteCreationFormEnUsLocale: Translations = {
  [TK.SITE_CREATION_FORM_TITLE_PLACEHOLDER]: 'Site Title',
  [TK.SITE_CREATION_FORM_URL_PLACEHOLDER]: 'Choose Site URL',
  [TK.SITE_CREATION_FORM_URL_DOMAIN_NAME]: '.missio.app',
  [TK.SITE_CREATION_FORM_URL_DESCRIPTION]: '*Site URL can not be changed when is set once.',
  [TK.SITE_CREATION_FORM_DESCRIPTION_PLACEHOLDER]: 'Site’s Short Description',
  [TK.SITE_CREATION_FORM_SUBMIT_BUTTON_LABEL]: 'Create My Site',
  [TK.SITE_CREATION_FORM_INVALID_SITE_TITLE_ERROR_MESSAGE]: 'Please enter a valid Site Title.',
  [TK.SITE_CREATION_FORM_INVALID_URL_ERROR_MESSAGE]: 'Please enter a valid URL.',
  [TK.SITE_CREATION_FORM_INVALID_DESCRIPTION_ERROR_MESSAGE]: 'Please enter a valid description.',
  [TK.SITE_CREATION_FORM_TAKEN_SITE_URL_ERROR_MESSAGE]: 'This site URL already exists. Try another one.',
  [TK.SITE_CREATION_FORM_TITLE_MAX_LENGTH_ERROR_MESSAGE]: 'The title cannot exceed 32 characters.',
  [TK.SITE_CREATION_FORM_URL_INVALID_ERROR_MESSAGE]: 'The site should only have numbers, alphabets or the special characters ‘; , / ? : @ & = + $ - _ . ! ~ * \' ( ) #’.',
  [TK.SITE_CREATION_FORM_DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE]: 'The description cannot exceed 60 characters.',
};

import { CommentsState } from 'components/comments/store/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  addComment, deleteComment, getComments, getReplies,
} from 'components/comments/store/actions';
import { createDefaultAPIRequestState } from 'store/utils/create-default-api-request-state';

const initialState: CommentsState = {
  comments: createDefaultAPIRequestState(null),
  addComment: createDefaultAPIRequestState(),
  replies: {},
  deleteComment: createDefaultAPIRequestState(),
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetComments: (state) => {
      state.comments.data = null;
    },
    resetReplyById: (state, { payload }: PayloadAction<string>) => {
      delete state.replies[payload];
    },
    resetReplies: (state) => {
      state.replies = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.comments.loading = true;
        state.comments.error = '';
      })
      .addCase(getComments.fulfilled, (state, { payload }) => {
        state.comments.data = payload;
        state.comments.loading = false;
      })
      .addCase(getComments.rejected, (state, { payload }) => {
        state.comments.loading = false;
        state.comments.error = payload as string;
      })

      .addCase(addComment.pending, (state, { meta }) => {
        const commentId = meta.arg?.pcid;

        state.addComment.loading = true;
        state.addComment.error = '';

        if (!commentId) {
          return;
        }

        if (!state.replies[commentId]) {
          state.replies[commentId] = createDefaultAPIRequestState(null);
        }

        state.replies[commentId].loading = true;
        state.replies[commentId].error = '';
      })
      .addCase(addComment.fulfilled, (state, { meta, payload }) => {
        const { commentsData, repliesData } = payload;
        const commentId = meta.arg?.pcid;

        state.comments.data = commentsData;
        state.addComment.loading = false;

        if (repliesData && commentId) {
          state.replies[commentId].data = repliesData;
          state.replies[commentId].loading = false;
        }
      })
      .addCase(addComment.rejected, (state, { meta, payload }) => {
        const commentId = meta.arg?.pcid;

        state.comments.loading = false;
        state.comments.error = payload as string;

        if (commentId) {
          state.replies[commentId].loading = false;
          state.replies[commentId].error = payload as string;
        }
      })

      .addCase(getReplies.pending, (state, { meta }) => {
        const commentId = meta.arg?.pcid;
        state.replies[commentId] = {
          ...createDefaultAPIRequestState(null),
          loading: true,
        };
      })
      .addCase(getReplies.fulfilled, (state, { meta, payload }) => {
        const commentId = meta.arg?.pcid;
        state.replies[commentId].data = payload;
        state.replies[commentId].loading = false;
      })
      .addCase(getReplies.rejected, (state, { meta, payload }) => {
        const commentId = meta.arg?.pcid;
        state.replies[commentId].loading = false;
        state.replies[commentId].error = payload as string;
      })

      .addCase(deleteComment.pending, (state) => {
        state.deleteComment.loading = true;
        state.deleteComment.error = '';
      })
      .addCase(deleteComment.fulfilled, (state) => {
        state.deleteComment.loading = false;
      })
      .addCase(deleteComment.rejected, (state, { payload }) => {
        state.deleteComment.loading = false;
        state.deleteComment.error = payload as string;
      })

      .addDefaultCase((state) => state);
  },
});

export const { resetComments, resetReplyById, resetReplies } = commentsSlice.actions;

export const commentsReducer = commentsSlice.reducer;

import * as TK from 'components/auth/GreetingsMessage/translations/constants';
import { Translations } from 'translations/types';

export const greetingsMessageEnUsLocale: Translations = {
  [TK.GREETINGS_MESSAGE_TITLE_MORNING]: 'Good Morning!',
  [TK.GREETINGS_MESSAGE_TITLE_AFTERNOON]: 'Good Afternoon!',
  [TK.GREETINGS_MESSAGE_TITLE_EVENING]: 'Good Evening!',
  [TK.GREETINGS_MESSAGE_SUBTITLE]: 'Welcome to',
  [TK.GREETINGS_MESSAGE_MISSIO_LABEL]: 'Missio.',
};

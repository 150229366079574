import { AppRoutes } from 'enums/app-routes.enum';
import { Loader } from 'components/common/Loader';
import { Navigate } from 'react-router-dom';
import { selectAuthLoading, selectUser } from 'components/auth/store/selectors';
import { useSelector } from 'react-redux';
import React, { FC, ReactElement } from 'react';

interface ProtectedRouteProps {
  redirectPath?: AppRoutes;
  children: ReactElement;
  skipSidCheck?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  redirectPath = AppRoutes.SIGN_IN,
  skipSidCheck = true,
  children,
}) => {
  const user = useSelector(selectUser);
  const loading = useSelector(selectAuthLoading);

  if (!(user || loading)) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!user && loading) {
    return <Loader isLoading />;
  }

  if (!(skipSidCheck || user?.sid)) {
    return <Navigate to={AppRoutes.SITE_CREATION} />;
  }

  return children;
};

ProtectedRoute.defaultProps = {
  redirectPath: AppRoutes.SIGN_IN,
  skipSidCheck: false,
};

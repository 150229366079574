import { APIRequest } from 'store/types';

interface DefaultARIRequestStateParameters {
  withPagination?: boolean;
}

export const createDefaultAPIRequestState = <T>(
  data?: T,
  { withPagination }: DefaultARIRequestStateParameters = {},
): APIRequest<T> => ({
  data,
  loading: false,
  ...(withPagination ? { nextPageLoading: false, isLastPage: false } : {}),
  error: '',
});

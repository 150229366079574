import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getUser,
  resetPassword,
  sendResetPasswordEmail,
  signIn,
  signUp,
  updateAvatar,
  verifyOtp,
} from 'components/auth/store/actions';

export interface AuthRequestState {
  error: string;
  loading: boolean;
}

export interface VerifyOtpState extends AuthRequestState {
  user?: User | null;
}

export interface User {
  sid: string | null;
  uid: string;
  email: string;
  username: string;
  profile_image?: string;
  last_sign_in_at?: string;
}

export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string;
  password?: string;
  signIn: AuthRequestState;
  signUp: AuthRequestState;
  verifyOtp: VerifyOtpState;
  forgotPassword: AuthRequestState;
  resetPassword: AuthRequestState;
}

const authRequestInitialState: AuthRequestState = {
  loading: false,
  error: '',
};

const initialState: AuthState = {
  user: null,
  loading: true,
  error: '',
  signIn: authRequestInitialState,
  signUp: authRequestInitialState,
  verifyOtp: authRequestInitialState,
  forgotPassword: authRequestInitialState,
  resetPassword: authRequestInitialState,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
      state.loading = false;
    },
    setPassword: (state, { payload }: PayloadAction<string>) => {
      state.password = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.error = '';
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      })
      .addCase(signIn.pending, (state) => {
        state.signIn.loading = true;
        state.signIn.error = '';
      })
      .addCase(signIn.fulfilled, (state) => {
        state.signIn.loading = false;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        state.signIn.error = payload as string;
        state.signIn.loading = false;
      })
      .addCase(signUp.pending, (state) => {
        state.signUp.loading = true;
        state.signUp.error = '';
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.verifyOtp.user = payload;
        state.signUp.loading = false;
      })
      .addCase(signUp.rejected, (state, { payload }) => {
        state.signUp.error = payload as string;
        state.signUp.loading = false;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.verifyOtp.loading = true;
        state.verifyOtp.error = '';
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.verifyOtp.loading = false;
        state.password = undefined;
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.verifyOtp.error = payload as string;
        state.verifyOtp.loading = false;
      })
      .addCase(sendResetPasswordEmail.pending, (state) => {
        state.forgotPassword.loading = true;
        state.forgotPassword.error = '';
      })
      .addCase(sendResetPasswordEmail.fulfilled, (state) => {
        state.forgotPassword.loading = false;
      })
      .addCase(sendResetPasswordEmail.rejected, (state, { payload }) => {
        state.forgotPassword.error = payload as string;
        state.forgotPassword.loading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.loading = true;
        state.resetPassword.error = '';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPassword.loading = false;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.resetPassword.error = payload as string;
        state.resetPassword.loading = false;
      })
      .addCase(updateAvatar.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(updateAvatar.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
        state.error = '';
      })
      .addCase(updateAvatar.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      })
      .addDefaultCase((state) => state);
  },
});

export const {
  setUser,
  setPassword,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

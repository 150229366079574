import { PaginationParameters } from 'store/utils/pagination';
import { Post } from 'components/post/store/types';
import { RootState } from 'store';
import { Site } from 'components/site/store/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSearchedPostsData, selectSearchedProfilesData } from 'components/search/store/selectors';
import { supabase } from 'configuration/supabaseClient';

interface GetSearchedParameters extends PaginationParameters {
  value: string;
}

const PROFILES_SIZE = 16;
const POSTS_SIZE = 12;

export const getSearchedProfiles = createAsyncThunk(
  'search/getSearchedProfiles',
  async (
    { loadNextPage, value }: GetSearchedParameters,
    { rejectWithValue, getState },
  ) => {
    let offset = 0;

    if (loadNextPage) {
      const profiles = selectSearchedProfilesData(getState() as RootState);
      offset = profiles?.length || 0;
    }

    const { data, error } = await supabase
      .from('all_sites')
      .select()
      .ilike('site_title', `%${value}%`)
      .range(offset, offset + PROFILES_SIZE - 1)
      .returns<Site[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const getSearchedPosts = createAsyncThunk(
  'search/getSearchedPosts',
  async (
    { loadNextPage, value }: GetSearchedParameters,
    { rejectWithValue, getState },
  ) => {
    let offset = 0;

    if (loadNextPage) {
      const posts = selectSearchedPostsData(getState() as RootState);
      offset = posts?.length || 0;
    }

    const { data, error } = await supabase
      .from('all_posts')
      .select()
      .ilike('title', `%${value}%`)
      .range(offset, offset + POSTS_SIZE - 1)
      .returns<Post[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

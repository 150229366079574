import * as TK from 'components/post/PostForm/translations/constants';
import { Translations } from 'translations/types';

export const postFormEnUsLocale: Translations = {
  [TK.POST_FORM_TITLE]: 'Write Post',
  [TK.POST_FORM_TITLE_PLACEHOLDER]: 'Title goes here',
  [TK.POST_FORM_BODY_PLACEHOLDER]: 'Write post description',
  [TK.POST_FORM_SUBMIT_BUTTON_LABEL]: 'Publish',
  [TK.POST_FORM_REQUIRED_ERROR_MESSAGE]: 'This field is required.',
  [TK.POST_FORM_TITLE_MAX_LENGTH_ERROR_MESSAGE]: 'The title cannot exceed 90 characters.',
  [TK.POST_FORM_HASHTAGS_TITLE]: 'Hashtags',
};

import { DOMAIN } from 'configuration/env';
import {
  DeleteCookieOptions,
  SetCookieOptions,
  deleteCookie,
  getCookie,
  setCookie,
} from 'utils/cookie';
import { YEAR } from 'utils/format-time';
import { supabase } from 'configuration/supabaseClient';

const ACCESS_TOKEN_COOKIE_KEY = 'access-token';
const REFRESH_TOKEN_COOKIE_KEY = 'refresh-token';

export const setSessionFromCookies = async () => {
  const accessToken = getCookie(ACCESS_TOKEN_COOKIE_KEY);
  const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_KEY);

  if (accessToken && refreshToken) {
    await supabase.auth.setSession({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
  }
};

export const deleteSessionCookies = () => {
  const options: DeleteCookieOptions = {
    samesite: 'lax',
    secure: true,
  };

  deleteCookie(ACCESS_TOKEN_COOKIE_KEY, options);
  deleteCookie(REFRESH_TOKEN_COOKIE_KEY, options);
};

export const setSessionCookies = (accessToken: string, refreshToken: string) => {
  const options: SetCookieOptions = {
    domain: DOMAIN,
    'max-age': 100 * YEAR,
    samesite: 'lax',
    secure: true,
  };

  setCookie(ACCESS_TOKEN_COOKIE_KEY, accessToken, options);
  setCookie(REFRESH_TOKEN_COOKIE_KEY, refreshToken, options);
};

import * as TK from 'components/auth/SignUpForm/translations/constants';
import { Translations } from 'translations/types';

export const signUpFormEnUsLocale: Translations = {
  [TK.SIGN_UP_FORM_USERNAME_PLACEHOLDER]: 'Name',
  [TK.SIGN_UP_FORM_EMAIL_PLACEHOLDER]: 'Email',
  [TK.SIGN_UP_FORM_PASSWORD_PLACEHOLDER]: 'Password',
  [TK.SIGN_UP_FORM_SUBMIT_BUTTON_LABEL]: 'Sign Up',
  [TK.SIGN_UP_FORM_TERMS_OF_USE]: '*by entering your email address you’re confirming that you agree with our Terms of Service.',
  [TK.SIGN_UP_FORM_USERNAME_REQUIRED_ERROR_MESSAGE]: 'This field is required.',
  [TK.SIGN_UP_FORM_USERNAME_INVALID_ERROR_MESSAGE]: 'Please enter a valid name.',
  [TK.SIGN_UP_FORM_EMAIL_INVALID_ERROR_MESSAGE]: 'Please enter a valid email address.',
  [TK.SIGN_UP_FORM_PASSWORD_INVALID_ERROR_MESSAGE]: 'Please enter at least 8 characters with one capital letter and one symbol.',
  [TK.SIGN_UP_FORM_EMAIL_EXISTS_ERROR_MESSAGE]: 'Email already exists. Try a different one.',
};

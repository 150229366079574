import {
  AuthRequestState, AuthState, User, VerifyOtpState,
} from 'components/auth/store/slice';
import { RootState } from 'store';

export const selectAuthSlice = (state: RootState): AuthState => state.auth;
export const selectUser = (state: RootState): User | null => selectAuthSlice(state).user;
export const selectAuthLoading = (state: RootState): boolean => selectAuthSlice(state).loading;
export const selectAuthSignInState = (
  state: RootState,
): AuthRequestState => selectAuthSlice(state).signIn;
export const selectAuthSignUpState = (
  state: RootState,
): AuthRequestState => selectAuthSlice(state).signUp;
export const selectAuthVerifyOtpState = (
  state: RootState,
): VerifyOtpState => selectAuthSlice(state).verifyOtp;
export const selectUnverifiedUser = (
  state: RootState,
): User | null => selectAuthVerifyOtpState(state).user || null;
export const selectPassword = (
  state: RootState,
): string | undefined => selectAuthSlice(state).password;
export const selectAuthForgotPasswordState = (
  state: RootState,
): AuthRequestState => selectAuthSlice(state).forgotPassword;
export const selectAuthResetPasswordState = (
  state: RootState,
): AuthRequestState => selectAuthSlice(state).resetPassword;

import { Loader } from 'components/common/Loader';
import React, { FC, Suspense } from 'react';

interface DefaultSuspenseProps {
  children: React.ReactNode;
}

export const DefaultSuspense: FC<DefaultSuspenseProps> = ({ children }) => (
  <Suspense fallback={<Loader isLoading />}>
    {children}
  </Suspense>
);

export interface Options {
  path: string;
  domain?: string;
  ['max-age']?: number;
  expires?: Date | string;
  samesite?: 'strict' | 'lax';
  secure?: boolean;
}

export type SetCookieOptions = Omit<Options, 'path'>;

export const setCookie = (
  name: string,
  value?: string,
  initialOptions: SetCookieOptions = {},
) => {
  const options: Options = {
    path: '/',
    ...initialOptions,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  const cookieEntry = `${encodeURIComponent(name)}=${encodeURIComponent(value || '')}`;

  document.cookie = Object.entries(options)
    .reduce(
      (
        accumulator,
        [key, initialOptionValue]: [string, string | number | boolean],
      ) => {
        const optionValue = initialOptionValue === true
          ? ''
          : `=${initialOptionValue || ''}`;

        return `${accumulator}; ${key}${optionValue}`;
      },
      cookieEntry,
    );
};

export const getCookie = (name: string): string | undefined => {
  const escapedName = name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1');
  const matches = document.cookie.match(new RegExp(`(?:^|; )${escapedName}=([^;]*)`));

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export type DeleteCookieOptions = Omit<Options, 'expires' | 'path' | 'max-age'>;

export const deleteCookie = (name: string, options: DeleteCookieOptions = {}) => setCookie(name, '', {
  expires: new Date(0).toUTCString(),
  ...options,
});

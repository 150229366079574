import { RootState } from 'store';
import { Site } from 'components/site/store/slice';
import { StorageBuckets } from 'components/media/enums/storage-buckets.enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteImages } from 'components/media/store/actions';
import { getMyPosts } from 'components/post/store/actions';
import { getUser, uploadAvatar } from 'components/auth/store/actions';
import { selectSiteInfo } from 'components/site/store/selectors';
import { selectUser } from 'components/auth/store/selectors';
import { supabase } from 'configuration/supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export interface SiteCreationData {
  title: string;
  url: string;
  description: string;
  avatar?: File | null;
}

interface CheckSiteUrlResponse {
  exists: boolean;
}

export interface UpdateAvatarData {
  oldAvatar?: string;
  avatar: File;
}

interface UploadCoverImage {
  error: {
    message: string;
  } | null;
  data?: Site | null;
}

export const createSite = createAsyncThunk(
  'site/createSite',
  async ({
    title,
    url,
    description,
    avatar,
  }: SiteCreationData, { dispatch, rejectWithValue, getState }) => {
    const user = selectUser(getState() as RootState);
    if (!user) {
      return rejectWithValue(null);
    }

    if (avatar) {
      const { error } = await uploadAvatar(avatar, user.uid);

      if (error) {
        return rejectWithValue(error.message);
      }
    }

    const { data, error } = await supabase
      .from('my_sites')
      .insert({
        site_title: title,
        site_url: url,
        site_short_description: description,
      })
      .select()
      .single();

    if (error) {
      return rejectWithValue(error.message);
    }

    await dispatch(getUser());

    return data as Site;
  },
);

export const checkSiteUrl = createAsyncThunk(
  'site/checkSiteUrl',
  async (url: string, { rejectWithValue }) => {
    const { data, error } = await supabase
      .rpc('check_site_url', { site_url: url });

    if (error) {
      rejectWithValue(error.message);
    }

    return data as CheckSiteUrlResponse;
  },
);

export const getSite = createAsyncThunk(
  'site/getSite',
  async (_, { rejectWithValue }) => {
    const { data, error } = await supabase
      .from('my_sites')
      .select()
      .single<Site>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const uploadCoverImage = async (
  image: File,
  sid: string,
): Promise<UploadCoverImage> => {
  const imgPath = `${sid}/${uuidv4()}.${image.name.split('.').pop() || ''}`;
  const { error } = await supabase.storage
    .from(StorageBuckets.MEDIA)
    .upload(imgPath, image);

  if (error) {
    return { error };
  }

  return supabase
    .from('my_sites')
    .update({ hero_image_url: imgPath })
    .eq('sid', sid)
    .select()
    .single();
};

export const updateCoverImage = createAsyncThunk(
  'site/updateCoverImage',
  async (image: File, { rejectWithValue, getState }) => {
    const site = selectSiteInfo(getState() as RootState);
    if (!site?.sid) {
      return rejectWithValue(null);
    }
    try {
      const { data, error } = await uploadCoverImage(image, site.sid);
      if (error) {
        return rejectWithValue(error.message);
      }

      if (site.hero_image_url) {
        await deleteImages([site.hero_image_url], StorageBuckets.MEDIA);
      }

      return data as Site;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteCoverImage = createAsyncThunk(
  'site/deleteCoverImage',
  async (_, { rejectWithValue, getState }) => {
    const site = selectSiteInfo(getState() as RootState);
    try {
      if (site?.hero_image_url) {
        await deleteImages([site.hero_image_url], StorageBuckets.MEDIA);
      }
      const { data } = await supabase
        .from('my_sites')
        .update({ hero_image_url: null })
        .eq('sid', site?.sid)
        .select()
        .single();
      return data as Site;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface SetGivingLinkParameters {
  link: string;
  sid: string;
}

export const setGivingLink = createAsyncThunk(
  'site/setGivingLink',
  async ({ link, sid }: SetGivingLinkParameters, { rejectWithValue, dispatch }) => {
    try {
      const { error } = await supabase
        .from('my_sites')
        .update({ give_url: link })
        .eq('sid', sid);

      if (error) {
        throw new Error(error.message);
      }

      dispatch(getMyPosts());
      dispatch(getSite());
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

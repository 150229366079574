import * as TK from 'components/profile/Sidebar/translations/constants';
import { Translations } from 'translations/types';

export const sidebarEnUsLocale: Translations = {
  [TK.SIDEBAR_COUNTER_POSTS]: 'Posts',
  [TK.SIDEBAR_COUNTER_PARTNERS]: 'Partners',
  [TK.SIDEBAR_LIST_HOME]: 'Home',
  [TK.SIDEBAR_LIST_MY_SITE]: 'My Site',
  [TK.SIDEBAR_LIST_CHAT]: 'Chat',
  [TK.SIDEBAR_LIST_PROFILE_SETTINGS]: 'Profile Settings',
  [TK.SIDEBAR_LIST_SITE_MANAGEMENT]: 'Site Management',
  [TK.SIDEBAR_LIST_MANAGE_UPDATES]: 'Manage Updates',
  [TK.SIDEBAR_LIST_PARTNERS]: 'Partners',
  [TK.SIDEBAR_LIST_POST_REMINDERS]: 'Post Reminders',
  [TK.SIDEBAR_LIST_BILLING]: 'Billing',
  [TK.SIDEBAR_LIST_INVITE_FRIEND]: 'Invite Friend',
  [TK.SIDEBAR_PRIVATE_PROFILE]: 'Make Profile Private',
};

import { RejectWithValue } from 'store/types';
import { RootState } from 'store';
import { StorageBuckets } from 'components/media/enums/storage-buckets.enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateFileName } from 'components/media/utils/generate-file-name';
import { isVideoPath } from 'utils/is-video-path';
import { selectMediaItemData } from 'components/media/store/selectors';
import { setMediaItemLoading } from 'components/media/store/slice';
import { supabase } from 'configuration/supabaseClient';

interface DownloadMediaData {
  bucket: StorageBuckets;
  url: string;
}

export interface FileUploadReturnData {
  path: string;
}

const SIGNED_URL_EXPIRATION_TIME = 3600;

const downloadImage = async (
  bucket: StorageBuckets,
  url: string,
  rejectWithValue: RejectWithValue,
) => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .download(url);
  if (error) {
    return rejectWithValue(error.message);
  }

  return URL.createObjectURL(data);
};

const createdSignedUrl = async (
  bucket: StorageBuckets,
  path: string,
  rejectWithValue: RejectWithValue,
) => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .createSignedUrl(path, SIGNED_URL_EXPIRATION_TIME);

  if (error) {
    return rejectWithValue(error.message);
  }

  return data.signedUrl;
};

export const downloadMedia = createAsyncThunk(
  'media/downloadMedia',
  (
    { bucket = StorageBuckets.MEDIA, url }: DownloadMediaData,
    { dispatch, getState, rejectWithValue },
  ) => {
    const media = selectMediaItemData(getState() as RootState, url);
    if (media.url || media.loading) {
      return null;
    }

    dispatch(setMediaItemLoading(url));

    if (isVideoPath(url)) {
      return createdSignedUrl(bucket, url, rejectWithValue);
    }

    return downloadImage(bucket, url, rejectWithValue);
  },
);

export const uploadMediaItem = async (
  file: File,
  folder: string,
  bucket: StorageBuckets = StorageBuckets.MEDIA,
): Promise<FileUploadReturnData> => {
  const path = `${folder}/${generateFileName(file.name)}`;

  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(path, file);

  if (error) {
    throw error;
  }

  return data;
};

export const uploadMedia = (
  files: File[],
  folder: string,
  bucket: StorageBuckets = StorageBuckets.MEDIA,
): Promise<FileUploadReturnData[]> => Promise.all(
  files.map((file) => uploadMediaItem(file, folder, bucket)),
);

export const deleteImages = async (urls: string[], type: StorageBuckets) => {
  const { error } = await supabase.storage.from(type).remove(urls);

  if (error) {
    throw new Error(error.message);
  }
};

import { APIRequest } from 'store/types';
import { RootState } from 'store';
import { Site, SiteState } from 'components/site/store/slice';

export const getSiteSlice = (state: RootState): SiteState => state.site;
export const selectCoverImageState = (
  state: RootState,
): APIRequest => getSiteSlice(state).coverImage;
export const selectSiteUrlState = (
  state: RootState,
): APIRequest => getSiteSlice(state).checkSiteUrl;
export const selectSiteUrlError = (
  state: RootState,
): string => selectSiteUrlState(state).error;
export const selectSiteInfo = (state: RootState): Site | null => getSiteSlice(state).site;
export const selectGivingLinkState = (
  state: RootState,
): APIRequest => getSiteSlice(state).givingLink;

export const videoExtensions = [
  'mp4',
  'mov',
  'wmv',
  'flv',
  'avi',
  'webm',
  'mkv',
  'mpeg',
];

export const parseExtension = (path?: string): string => (path || '').split('.').pop() || '';

export const isVideoPath = (path?: string) => (
  videoExtensions.includes(parseExtension(path).toLowerCase()));

import * as TK from 'components/post/PostCreated/translations/constants';
import { Translations } from 'translations/types';

export const postCreatedEnUsLocale: Translations = {
  [TK.POST_CREATED_ICON_ALT]: 'Published icon',
  [TK.POST_CREATED_TITLE]: 'Published!',
  [TK.POST_CREATED_DESCRIPTION]: 'Your post is published now! It will be sent to subscribers',
  [TK.POST_CREATED_UPDATES_TIME]: '(Tuesday night at 12am PST)',
  [TK.POST_CREATED_ADD_POST_BUTTON_TEXT]: 'Add another Post',
  [TK.POST_CREATED_NAVIGATE_HOME_BUTTON_TEXT]: 'Go to Home',
};

export const SIDEBAR_COUNTER_POSTS = 'sidebar.counter-posts';
export const SIDEBAR_COUNTER_PARTNERS = 'sidebar.counter-partners';
export const SIDEBAR_LIST_HOME = 'sidebar.list-home';
export const SIDEBAR_LIST_MY_SITE = 'sidebar.list-my-site';
export const SIDEBAR_LIST_CHAT = 'sidebar.list-chat';
export const SIDEBAR_LIST_PROFILE_SETTINGS = 'sidebar.list-profile-settings';
export const SIDEBAR_LIST_SITE_MANAGEMENT = 'sidebar.list-site-management';
export const SIDEBAR_LIST_MANAGE_UPDATES = 'sidebar.list-manage-updates';
export const SIDEBAR_LIST_PARTNERS = 'sidebar.list-partners';
export const SIDEBAR_LIST_POST_REMINDERS = 'sidebar.list-post-reminders';
export const SIDEBAR_LIST_BILLING = 'sidebar.list-billing';
export const SIDEBAR_LIST_INVITE_FRIEND = 'sidebar.list-invite-friend';
export const SIDEBAR_PRIVATE_PROFILE = 'sidebar.private-profile';

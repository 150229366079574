import { FC, ReactElement, useEffect } from 'react';
import {
  deleteSessionCookies,
  setSessionCookies,
  setSessionFromCookies,
} from 'components/auth/AuthProvider/cookie-session';
import { getSite } from 'components/site/store/actions';
import { getUser } from 'components/auth/store/actions';
import { setSite } from 'components/site/store/slice';
import { setUser } from 'components/auth/store/slice';
import { supabase } from 'configuration/supabaseClient';
import { useAppDispatch } from 'store';

interface AuthProviderProps {
  children: ReactElement;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSessionFromCookies().then(() => {
      dispatch(getUser());
      dispatch(getSite());
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED')) {
        setSessionCookies(session.access_token, session.refresh_token);
      }

      switch (event) {
        case 'SIGNED_IN':
          dispatch(getUser());
          dispatch(getSite());
          break;
        case 'USER_UPDATED':
          dispatch(getUser());
          break;
        case 'SIGNED_OUT':
          dispatch(setUser(null));
          dispatch(setSite(null));
          deleteSessionCookies();
          break;
        case 'USER_DELETED':
          deleteSessionCookies();
          break;
        default:
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [dispatch]);

  return children;
};

import * as TK from 'components/common/TextEditor/components/LinkAttachmentForm/translations/constants';
import { Translations } from 'translations/types';

export const linkAttachmentFormEnUsLocale: Translations = {
  [TK.LINK_ATTACHMENT_FORM_NAME_FIELD_PLACEHOLDER]: 'Link Name/Alias',
  [TK.LINK_ATTACHMENT_FORM_LINK_FIELD_PLACEHOLDER]: 'https://www.somelink.com',
  [TK.LINK_ATTACHMENT_FORM_INVALID_URL_ERROR_MESSAGE]: 'URL is invalid.',
  [TK.LINK_ATTACHMENT_FORM_REQUIRED_ERROR_MESSAGE]: 'This field is required.',
  [TK.LINK_ATTACHMENT_FORM_CANCEL_BUTTON_LABEL]: 'Cancel',
  [TK.LINK_ATTACHMENT_FORM_SUBMIT_BUTTON_LABEL]: 'Save',
};

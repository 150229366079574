import * as TK from 'components/post/PostMenu/translations/constants';
import { Translations } from 'translations/types';

export const postMenuEnUsLocale: Translations = {
  [TK.POST_MENU_COPY_LINK]: 'Copy Link',
  [TK.POST_MENU_HIDE_POST]: 'Hide this post',
  [TK.POST_MENU_EDIT]: 'Edit',
  [TK.POST_MENU_DELETE]: 'Delete',
  [TK.POST_MENU_DELETION_DIALOG_MESSAGE]: 'Are you sure you want to delete this post?',
};

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const YEAR = 365 * DAY;

export function formatTime(dateString: string): string {
  const now = new Date();
  const difference = now.getTime() - new Date(dateString).getTime();

  if (difference < MINUTE) {
    return `${Math.floor(difference / SECOND)}s`;
  } if (difference < HOUR) {
    return `${Math.floor(difference / MINUTE)}m`;
  } if (difference < DAY) {
    return `${Math.floor(difference / HOUR)}h`;
  } if (difference < WEEK) {
    return `${Math.floor(difference / DAY)}d`;
  } if (difference < YEAR) {
    return `${Math.floor(difference / WEEK)}w`;
  }
  return `${Math.floor(difference / YEAR)}y`;
}

import { Theme } from '@mui/material/styles/createTheme';
import { alpha, createTheme } from '@mui/material/styles';
import defaultProfileImage from 'assets/icons/default-profile.svg';

const getTheme = (): Theme => {
  const initialTheme = createTheme({
    breakpoints: {
      values: {
        xs: 420,
        sm: 768,
        md: 1024,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: {
      mode: 'light',
      common: {
        black: '#222222',
      },
      primary: {
        main: '#0085FF',
      },
      secondary: {
        main: '#070B0F',
      },
      grey: {
        50: '#FAFAFB',
        100: '#E9EBED',
        200: '#A7AFB8',
        300: '#7B8794',
        400: '#4F5F71',
        500: '#23374D',
        600: '#15212E',
        700: '#070B0F',
        900: '#111827',
      },
      text: {
        primary: '#070B0F',
      },
      error: {
        main: '#FF4747',
      },
      divider: '#E5E7EB',
    },
  });

  return createTheme(
    {
      ...initialTheme,
      typography: {
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        fontSize: 14,
        h1: {
          fontSize: 28,
          lineHeight: '34px',
          fontWeight: 500,
          [initialTheme.breakpoints.up('md')]: {
            fontSize: 48,
            lineHeight: '59px',
          },
        },
        h2: {
          fontSize: 36,
          lineHeight: '44px',
          fontWeight: 700,
        },
        h3: {
          fontSize: 32,
          lineHeight: '36px',
          fontWeight: 700,
        },
        h4: {
          fontSize: 28,
          lineHeight: 1.5,
          fontWeight: 500,
        },
        h5: {
          fontSize: 24,
          lineHeight: '28px',
          fontWeight: 700,
        },
        h6: {
          fontSize: 18,
          lineHeight: '22px',
          fontWeight: 700,
        },
        body1: {
          fontSize: 14,
        },
        body2: {
          fontSize: 16,
          lineHeight: 1.25,
        },
      },
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'contained',
          },
          styleOverrides: {
            root: {
              padding: initialTheme.spacing(1, 3),
              borderRadius: 40,
              fontWeight: 700,
              lineHeight: 1.5,
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              '&.Mui-disabled': {
                backgroundColor: initialTheme.palette.grey[200],
                color: initialTheme.palette.common.white,
              },
              '&.MuiLoadingButton-root': {
                color: initialTheme.palette.common.white,
              },
            },
            contained: {
              backgroundColor: initialTheme.palette.common.black,
              '&:hover': {
                backgroundColor: '#006ACC',
              },
            },
            outlined: {
              borderColor: initialTheme.palette.grey[500],
              color: initialTheme.palette.grey[500],
            },
            text: {
              color: initialTheme.palette.grey[900],
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
            sizeLarge: {
              padding: initialTheme.spacing(1.875, 3),
              fontSize: 18,
              lineHeight: '24px',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              fontSize: 14,
              lineHeight: 1.5,
              borderRadius: 40,
              backgroundColor: initialTheme.palette.grey[50],
              color: initialTheme.palette.secondary.main,
              ':hover .MuiOutlinedInput-notchedOutline': {
                borderColor: initialTheme.palette.primary.main,
              },
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: 1,
                },
              },
            },
            input: {
              padding: initialTheme.spacing(2, 2.5),
              height: 'auto',
              '::placeholder': {
                color: initialTheme.palette.grey[200],
              },
              '&.MuiInputBase-inputAdornedEnd:-webkit-autofill': {
                borderRadius: '40px 0 0 40px',
              },
            },
            notchedOutline: {
              borderColor: 'transparent',
              transition: 'border-color .25s',
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              padding: 0,
              fontSize: 16,
              lineHeight: '24px',
              [initialTheme.breakpoints.up('md')]: {
                fontSize: 20,
              },
            },
            input: {
              padding: initialTheme.spacing(2, 0),
              '::placeholder': {
                color: '#C7C7C7',
                opacity: 1,
              },
            },
            underline: {
              borderBottom: `1px solid ${initialTheme.palette.grey[100]}`,
              transition: 'border-color .25s',
              '&::before, &::after': {
                display: 'none',
              },
              '&.Mui-focused, &:hover': {
                borderColor: initialTheme.palette.secondary.main,
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              padding: initialTheme.spacing(0.75),
              marginLeft: initialTheme.spacing(0.675),
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              padding: initialTheme.spacing(1),
              ':hover': {
                backgroundColor: alpha(initialTheme.palette.grey[300], 0.06),
              },
              '> .MuiSvgIcon-root': {
                color: initialTheme.palette.grey[300],
              },
              '&.Mui-checked > .MuiSvgIcon-root': {
                color: initialTheme.palette.common.black,
              },
              '.MuiTouchRipple-root': {
                color: initialTheme.palette.grey[300],
              },
            },
          },
        },
        MuiFormGroup: {
          styleOverrides: {
            root: {
              '&[role="radiogroup"] > .MuiFormControlLabel-root': {
                margin: initialTheme.spacing(0, 1.5, 0, -1),
                '&.MuiFormControlLabel-labelPlacementEnd > .MuiFormControlLabel-label': {
                  marginLeft: initialTheme.spacing(1),
                  fontSize: 14,
                  lineHeight: 1.5,
                },
              },
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              marginTop: initialTheme.spacing(1.25),
              marginLeft: 0,
              fontSize: 14,
              lineHeight: '18px',
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: 16,
              padding: initialTheme.spacing(3, 2),
              [initialTheme.breakpoints.down('md')]: {
                padding: initialTheme.spacing(4),
              },
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: initialTheme.spacing(3, 0, 0),
              justifyContent: 'center',
              '> *:not(:first-of-type)': {
                marginLeft: initialTheme.spacing(2),
              },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            wrapper: {
              padding: initialTheme.spacing(0, 1.5),
              fontSize: 14,
              lineHeight: '18px',
              color: initialTheme.palette.grey[300],
            },
            wrapperVertical: {
              padding: initialTheme.spacing(1.5, 0),
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              height: 28,
              padding: initialTheme.spacing(0.5, 0),
              borderRadius: 34,
              backgroundColor: initialTheme.palette.grey[100],
            },
            label: {
              fontSize: 14,
              lineHeight: 1.44,
              color: initialTheme.palette.common.black,
            },
          },
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: 'transparent',
            },
            fallback: {
              background: `url(${defaultProfileImage}) center no-repeat`,
              backgroundSize: '100% 100%',
              width: '100%',
              height: '100%',
              path: {
                display: 'none',
              },
            },
          },
        },
        MuiSkeleton: {
          defaultProps: {
            variant: 'rectangular',
          },
        },
        MuiMenu: {
          styleOverrides: {
            list: {
              padding: 0,
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            thumb: {
              color: initialTheme.palette.common.white,
              border: `2px solid ${initialTheme.palette.primary.main}`,
            },
            rail: {
              color: initialTheme.palette.grey[200],
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 42,
              height: 26,
              padding: 0,
              '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                  transform: 'translateX(16px)',
                  color: initialTheme.palette.common.white,
                  '& + .MuiSwitch-track': {
                    backgroundColor: initialTheme.palette.common.black,
                    opacity: 1,
                    border: 0,
                  },
                  '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                  },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                  color: '#33cf4d',
                  border: `6px solid ${initialTheme.palette.common.white}`,
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                  color: initialTheme.palette.mode === 'light'
                    ? initialTheme.palette.grey[200]
                    : initialTheme.palette.grey[700],
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: initialTheme.palette.mode === 'light' ? 0.7 : 0.3,
                },
              },
              '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 22,
                height: 22,
              },
              '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: initialTheme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                opacity: 1,
                transition: initialTheme.transitions.create(['background-color'], {
                  duration: 500,
                }),
              },
            },
          },
        },
      },
    },
  );
};

export const theme = getTheme();

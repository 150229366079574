import * as styles from 'components/common/Loader/styles';
import { Backdrop, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

interface LoaderProps {
  isLoading: boolean;
}

export const Loader: FC<LoaderProps> = ({ isLoading }) => (
  <Backdrop
    sx={styles.backdrop}
    open={isLoading}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

import { Comment } from 'components/comments/store/types';
import { Reaction } from 'components/post/store/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from 'configuration/supabaseClient';

export const fetchComments = async (pid: string, pcid?: string) => {
  const { data, error } = await supabase.rpc('post_comments', { pid, replying_to_pcid: pcid });

  if (error) {
    throw new Error(error.message);
  }

  return data as Comment[];
};

export const getComments = createAsyncThunk(
  'comments/getComments',
  async (pid: string, { rejectWithValue }) => {
    try {
      const data = await fetchComments(pid);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface AddCommentParameters {
  pid: string;
  sid: string;
  comment: string;
  pcid?: string;
}

export const addComment = createAsyncThunk(
  'comments/addComment',
  async ({
    pid, sid, comment, pcid,
  }: AddCommentParameters, { rejectWithValue }) => {
    try {
      const { error } = await supabase.rpc('add_post_comment', {
        pid, sid, comment, replying_to_pcid: pcid,
      });

      if (error) {
        return rejectWithValue(error.message);
      }

      const [commentsData, repliesData] = await Promise.all([
        fetchComments(pid),
        pcid ? fetchComments(pid, pcid) : undefined,
      ]);

      return { commentsData, repliesData };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface GetRepliesParameters {
  pid: string;
  pcid: string;
}

export const getReplies = createAsyncThunk(
  'comments/getReplies',
  async ({ pid, pcid }: GetRepliesParameters, { rejectWithValue }) => {
    try {
      const data = await fetchComments(pid, pcid);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

interface ReactToPostCommentParameters {
  pcid: string;
  sid: string;
  reaction: string;
}

export const reactToPostComment = createAsyncThunk(
  'comments/reactToPostComment',
  async ({
    pcid, sid, reaction,
  }: ReactToPostCommentParameters, { rejectWithValue }) => {
    const { data, error } = await supabase.rpc('react_to_post_comment', {
      pcid, sid, reaction,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    return data as Reaction[];
  },
);

interface DeleteCommentParameters {
  id: string;
  parentId: string | null;
  postId: string;
}

export const deleteComment = createAsyncThunk(
  'comments/deleteComment',
  async ({ id, parentId, postId }: DeleteCommentParameters, { rejectWithValue, dispatch }) => {
    const { error } = await supabase.rpc('delete_post_comment', { pcid: id });

    if (error) {
      return rejectWithValue(error.message);
    }

    if (parentId) {
      dispatch(getComments(postId));
      dispatch(getReplies({ pid: postId, pcid: parentId }));
    } else {
      dispatch(getComments(postId));
    }
  },
);

import { SearchState } from 'components/search/store/types';
import { createDefaultAPIRequestState } from 'store/utils/create-default-api-request-state';
import { createSlice } from '@reduxjs/toolkit';
import { getSearchedPosts, getSearchedProfiles } from 'components/search/store/actions';
import {
  handleFulfilledRequestWithPagination,
  handlePendingRequestWithPagination,
  handleRejectedRequestWithPagination,
} from 'store/utils/pagination';

const initialState: SearchState = {
  profiles: createDefaultAPIRequestState(null, { withPagination: true }),
  posts: createDefaultAPIRequestState(null, { withPagination: true }),
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.profiles.data = null;
      state.posts.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchedProfiles.pending, (state, { meta }) => {
        handlePendingRequestWithPagination(state.profiles, meta.arg);
      })
      .addCase(getSearchedProfiles.fulfilled, (state, { payload, meta }) => {
        handleFulfilledRequestWithPagination(state.profiles, payload, meta.arg);
      })
      .addCase(getSearchedProfiles.rejected, (state, { meta, payload }) => {
        handleRejectedRequestWithPagination(state.profiles, payload, meta.arg);
      })

      .addCase(getSearchedPosts.pending, (state, { meta }) => {
        handlePendingRequestWithPagination(state.posts, meta.arg);
      })
      .addCase(getSearchedPosts.fulfilled, (state, { payload, meta }) => {
        handleFulfilledRequestWithPagination(state.posts, payload, meta.arg);
      })
      .addCase(getSearchedPosts.rejected, (state, { meta, payload }) => {
        handleRejectedRequestWithPagination(state.posts, payload, meta.arg);
      })

      .addDefaultCase((state) => state);
  },
});

export const { resetSearch } = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

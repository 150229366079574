import * as TK from 'components/site/SiteCreationForm/translations/constants';
import { APIRequest } from 'store/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  checkSiteUrl,
  createSite,
  deleteCoverImage,
  getSite,
  setGivingLink,
  updateCoverImage,
} from 'components/site/store/actions';
import { createDefaultAPIRequestState } from 'store/utils/create-default-api-request-state';

export interface Site {
  sid: string;
  post_count: number;
  partner_count: number;
  site_title: string;
  site_short_description: string;
  hero_image_url?: string | null;
  give_url?: string;
  about_content?: string | null;
  about_title?: string | null;
  about_image_url?: string | null;
}

export interface SiteState {
  site: Site | null;
  checkSiteUrl: APIRequest;
  coverImage: APIRequest;
  givingLink: APIRequest;
  loading: boolean;
  error: string;
}

const initialState: SiteState = {
  site: null,
  checkSiteUrl: createDefaultAPIRequestState(),
  coverImage: createDefaultAPIRequestState(),
  givingLink: createDefaultAPIRequestState(),
  loading: false,
  error: '',
};

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSite: (state, action: PayloadAction<Site | null>) => {
      state.site = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSite.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(createSite.fulfilled, (state, { payload }) => {
        state.site = payload;
        state.loading = false;
        state.error = '';
      })
      .addCase(createSite.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      })

      .addCase(checkSiteUrl.pending, (state) => {
        state.checkSiteUrl.loading = true;
        state.checkSiteUrl.error = '';
      })
      .addCase(checkSiteUrl.fulfilled, (state, { payload }) => {
        state.checkSiteUrl.loading = false;
        state.checkSiteUrl.error = payload.exists ? TK.SITE_CREATION_FORM_TAKEN_SITE_URL_ERROR_MESSAGE : '';
      })
      .addCase(checkSiteUrl.rejected, (state, { payload }) => {
        state.checkSiteUrl.loading = false;
        state.checkSiteUrl.error = payload as string;
      })

      .addCase(getSite.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getSite.fulfilled, (state, { payload }) => {
        state.site = payload;
        state.loading = false;
      })
      .addCase(getSite.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      })

      .addCase(updateCoverImage.pending, (state) => {
        state.coverImage.loading = true;
        state.error = '';
      })
      .addCase(updateCoverImage.fulfilled, (state, { payload }) => {
        state.site = payload;
        state.coverImage.loading = false;
      })
      .addCase(updateCoverImage.rejected, (state, { payload }) => {
        state.coverImage.loading = false;
        state.error = payload as string;
      })

      .addCase(deleteCoverImage.pending, (state) => {
        state.coverImage.loading = true;
        state.error = '';
      })
      .addCase(deleteCoverImage.fulfilled, (state, { payload }) => {
        state.site = payload;
        state.coverImage.loading = false;
      })
      .addCase(deleteCoverImage.rejected, (state, { payload }) => {
        state.coverImage.loading = false;
        state.error = payload as string;
      })

      .addCase(setGivingLink.pending, (state) => {
        state.givingLink.loading = true;
        state.givingLink.error = '';
      })
      .addCase(setGivingLink.fulfilled, (state) => {
        state.givingLink.loading = false;
      })
      .addCase(setGivingLink.rejected, (state, { payload }) => {
        state.givingLink.loading = false;
        state.givingLink.error = payload as string;
      })

      .addDefaultCase((state) => state);
  },
});

export const {
  setSite,
} = siteSlice.actions;

export const siteReducer = siteSlice.reducer;

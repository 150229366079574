export const SITE_CREATION_FORM_TITLE_PLACEHOLDER = 'site-creation-form.title-placeholder';
export const SITE_CREATION_FORM_URL_PLACEHOLDER = 'site-creation-form.url-placeholder';
export const SITE_CREATION_FORM_URL_DOMAIN_NAME = 'site-creation-form.url-domain-name';
export const SITE_CREATION_FORM_URL_DESCRIPTION = 'site-creation-form.url-description';
export const SITE_CREATION_FORM_DESCRIPTION_PLACEHOLDER = 'site-creation-form.description-placeholder';
export const SITE_CREATION_FORM_SUBMIT_BUTTON_LABEL = 'site-creation-form.submit-button-label';
export const SITE_CREATION_FORM_REQUIRED_ERROR_MESSAGE = 'site-creation-form.required-error-message';
export const SITE_CREATION_FORM_INVALID_SITE_TITLE_ERROR_MESSAGE = 'site-creation-form.invalid-site-title-error-message';
export const SITE_CREATION_FORM_INVALID_URL_ERROR_MESSAGE = 'site-creation-form.invalid-url-error-message';
export const SITE_CREATION_FORM_INVALID_DESCRIPTION_ERROR_MESSAGE = 'site-creation-form.invalid-site-description-message';
export const SITE_CREATION_FORM_TAKEN_SITE_URL_ERROR_MESSAGE = 'site-creation-form.taken-site-url-error-message';
export const SITE_CREATION_FORM_TITLE_MAX_LENGTH_ERROR_MESSAGE = 'site-creation-form.title-max-length-error-message';
export const SITE_CREATION_FORM_URL_INVALID_ERROR_MESSAGE = 'site-creation-form.url-invalid-error-message';
export const SITE_CREATION_FORM_DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE = 'site-creation-form.description-max-length-error-message';

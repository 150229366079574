import { APIRequest } from 'store/types';
import { Post } from 'components/post/store/types';
import { RootState } from 'store';
import { SearchState } from 'components/search/store/types';
import { Site } from 'components/site/store/slice';

export const selectSearchSlice = (state: RootState): SearchState => state.search;
export const selectSearchedProfilesState = (
  state: RootState,
): APIRequest<Site[] | null> => selectSearchSlice(state).profiles;
export const selectSearchedProfilesData = (
  state: RootState,
): Site[] | null => selectSearchedProfilesState(state).data || null;
export const selectSearchedPostsState = (
  state: RootState,
): APIRequest<Post[] | null> => selectSearchSlice(state).posts;
export const selectSearchedPostsData = (
  state: RootState,
): Post[] | null => selectSearchedPostsState(state).data || null;

import { DOMAIN, IS_BASIC_AUTH_ENABLED } from 'configuration/env';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { YEAR } from 'utils/format-time';
import { getCookie, setCookie } from 'utils/cookie';
import { supabase } from 'configuration/supabaseClient';

interface BasicAuthProps {
  children: ReactElement;
}

type AuthorizeResponse = {
  access_granted: boolean;
} | null;

const BASIC_AUTH_STORAGE_KEY = 'BASIC_AUTH';

export const createExclusiveExecutionWrapper = (fn: () => Promise<void>): () => Promise<void> => {
  let isExecuting = false;

  return async () => {
    if (isExecuting) {
      return;
    }

    isExecuting = true;

    try {
      await fn();
    } finally {
      isExecuting = false;
    }
  };
};

export const BasicAuth: FC<BasicAuthProps> = ({ children }) => {
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>(!IS_BASIC_AUTH_ENABLED);

  const tryAuthorize = useCallback(async () => {
    let token = getCookie(BASIC_AUTH_STORAGE_KEY);
    if (!token) {
      while (!token) {
        // eslint-disable-next-line no-alert
        token = prompt('Please enter access token') || '';
      }
    }

    const { data } = await supabase.functions
      .invoke('authorize', {
        headers: {
          'Basic-Authorization': token,
        },
      });

    if ((data as AuthorizeResponse)?.access_granted) {
      setIsPermissionGranted(true);
      setCookie(BASIC_AUTH_STORAGE_KEY, token, {
        domain: DOMAIN,
        'max-age': 100 * YEAR,
        samesite: 'lax',
        secure: true,
      });
    } else {
      await tryAuthorize();
    }
  }, []);

  const authorize = useMemo(() => createExclusiveExecutionWrapper(tryAuthorize), [tryAuthorize]);

  useEffect(() => {
    if (IS_BASIC_AUTH_ENABLED) {
      authorize();
    }
  }, [authorize]);

  return isPermissionGranted ? children : null;
};

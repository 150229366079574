import * as TK from 'components/auth/ResetPasswordForm/translations/constants';
import { Translations } from 'translations/types';

export const resetPasswordFormEnUsLocale: Translations = {
  [TK.RESET_PASSWORD_FORM_PASSWORD_PLACEHOLDER]: 'Password',
  [TK.RESET_PASSWORD_FORM_CONFIRM_PASSWORD_PLACEHOLDER]: 'Confirm Password',
  [TK.RESET_PASSWORD_FORM_PASSWORD_INVALID_ERROR_MESSAGE]: 'Please enter at least 8 characters with one capital letter and one symbol.',
  [TK.RESET_PASSWORD_FORM_PASSWORDS_NOT_MATCH_ERROR_MESSAGE]: 'The passwords do not match.',
  [TK.RESET_PASSWORD_FORM_SUBMIT_BUTTON_LABEL]: 'Submit',
};

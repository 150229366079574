export const SIGN_UP_FORM_USERNAME_PLACEHOLDER = 'sign-up-form.username-placeholder';
export const SIGN_UP_FORM_EMAIL_PLACEHOLDER = 'sign-up-form.email-placeholder';
export const SIGN_UP_FORM_PASSWORD_PLACEHOLDER = 'sign-up-form.password-placeholder';
export const SIGN_UP_FORM_SUBMIT_BUTTON_LABEL = 'sign-up-form.submit-button-label';
export const SIGN_UP_FORM_TERMS_OF_USE = 'sign-up-form.terms-of-use';
export const SIGN_UP_FORM_USERNAME_REQUIRED_ERROR_MESSAGE = 'sign-up-form.username-required-error-message';
export const SIGN_UP_FORM_USERNAME_INVALID_ERROR_MESSAGE = 'sign-up-form.username-invalid-error-message';
export const SIGN_UP_FORM_EMAIL_INVALID_ERROR_MESSAGE = 'sign-up-form.email-invalid-error-message';
export const SIGN_UP_FORM_PASSWORD_INVALID_ERROR_MESSAGE = 'sign-up-form.password-invalid-error-message';
export const SIGN_UP_FORM_EMAIL_EXISTS_ERROR_MESSAGE = 'sign-up-form.email-exists-error-message';

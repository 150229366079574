import * as TK from 'components/post/PostingPreferences/translations/constants';
import { Translations } from 'translations/types';

export const postingPreferencesEnUsLocale: Translations = {
  [TK.POSTING_PREFERENCES_TITLE]: 'Posting Preferences',
  [TK.POSTING_PREFERENCES_HIGHLIGHT_TITLE]: 'Highlight this post',
  [TK.POSTING_PREFERENCES_HIGHLIGHT_DESCRIPTION]: '(Set this at the top of my scheduled email update. Use this to showcase your most important updates.)',
  [TK.POSTING_PREFERENCES_TEMPORARY_TITLE]: 'Set this as a temporary post',
  [TK.POSTING_PREFERENCES_TEMPORARY_DESCRIPTION]: '(Disappears after 1 month)',
  [TK.POSTING_PREFERENCES_SHOW_GIVING_LINK_TITLE]: 'Show giving link',
  [TK.POSTING_PREFERENCES_PUSH_IMMEDIATELY_TITLE]: 'Push this post out immediately to all my subscribers',
  [TK.POSTING_PREFERENCES_PUSH_IMMEDIATELY_DESCRIPTION]: '(Only use for urgent posts when necessary)',
};

import { APIRequest } from 'store/types';
import { Draft } from '@reduxjs/toolkit';

export interface PaginationParameters {
  loadNextPage?: boolean;
}

export const handlePendingRequestWithPagination = <T, P extends PaginationParameters>(
  slice: Draft<APIRequest<T>>,
  parameters?: P,
) => {
  if (parameters?.loadNextPage) {
    slice.nextPageLoading = true;
  } else {
    slice.loading = true;
    slice.isLastPage = false;
  }

  slice.error = '';
};

export const handleFulfilledRequestWithPagination = <T, P extends PaginationParameters>(
  slice: Draft<APIRequest<T[] | null>>,
  payload: Draft<T>[],
  parameters?: P,
) => {
  if (parameters?.loadNextPage) {
    slice.data = [...(slice.data || []), ...payload];
  } else {
    slice.data = payload;
  }

  if (payload.length === 0) {
    slice.isLastPage = true;
  }

  slice.loading = false;
  slice.nextPageLoading = false;
};

export const handleRejectedRequestWithPagination = <T, P extends PaginationParameters>(
  slice: Draft<APIRequest<T[] | null>>,
  payload: unknown,
  parameters?: P,
) => {
  if (parameters?.loadNextPage) {
    slice.nextPageLoading = false;
  } else {
    slice.loading = false;
  }

  slice.error = payload as string;
};

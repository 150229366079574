import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { downloadMedia } from 'components/media/store/actions';

export interface Media {
  url?: string;
  loading: boolean;
  error?: string;
}

export interface MediaState {
  data: Record<string, Media>;
}

const initialState: MediaState = {
  data: {},
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMediaItemLoading: (state, { payload: url }: PayloadAction<string>) => {
      if (!state.data[url]) {
        state.data[url] = {
          loading: true,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadMedia.fulfilled, (state, { payload, meta }) => {
        const originalUrl = meta.arg?.url;

        if (payload && originalUrl) {
          state.data[originalUrl] = {
            url: payload,
            loading: false,
          };
        }
      })
      .addCase(downloadMedia.rejected, (state, { payload, meta }) => {
        const originalUrl = meta.arg?.url;

        if (originalUrl) {
          state.data[originalUrl] = {
            error: payload as string,
            loading: false,
          };
        }
      })
      .addDefaultCase((state) => state);
  },
});

export const {
  setMediaItemLoading,
} = mediaSlice.actions;

export const mediaReducer = mediaSlice.reducer;

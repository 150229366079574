import { ResourceLanguage } from 'i18next';
import { aboutSiteEnUsLocale } from 'components/site/AboutSite/translations/en-us.locale';
import { allReactionsDialogEnUsLocale } from 'components/post/AllReactionsDialog/translations/en-us.locale';
import { authPageEnUsLocale } from 'components/auth/AuthPageWrapper/translations/en-us.locale';
import { authProvidersEnUsLocale } from 'components/auth/SocialAuthProviders/translations/en-us.locale';
import { backButtonEnUsLocale } from 'components/common/BackButton/translations/en-us.locale';
import { commentEnUsLocale } from 'components/comments/Comment/translations/en-us.locale';
import { commentFormEnUsLocale } from 'components/comments/CommentForm/translations/en-us.locale';
import { commentMenuEnUsLocale } from 'components/comments/CommentMenu/translations/en-us.locale';
import { commentsEnUsLocale } from 'components/comments/Comments/translations/en-us.locale';
import { coverImageEnUsLocale } from 'components/site/CoverImage/translations/en-us.locale';
import { deletionDialogEnUsLocale } from 'components/common/DeletionDialog/translations/en-us.locale';
import { editPostPageEnUsLocale } from 'pages/EditPostPage/translations/en-us.locale';
import {
  emailVerificationDialogEnUsLocale,
} from 'components/auth/EmailVerificationDialog/translations/en-us.locale';
import {
  emailVerificationFormEnUsLocale,
} from 'components/auth/EmailVerificationForm/translations/en-us.locale';
import { emailVerificationPageEnUsLocale } from 'pages/EmailVerificationPage/translations/en-us.locale';
import { forgotPasswordDialogEnUsLocale } from 'components/auth/ForgotPasswordDialog/translations/en-us.locale';
import { forgotPasswordFormEnUsLocale } from 'components/auth/ForgotPasswordForm/translations/en-us.locale';
import { forgotPasswordPageEnUsLocale } from 'pages/ForgotPasswordPage/translations/en-us.locale';
import { givingLinkButtonEnUsLocale } from 'components/post/GivingLinkButton/translations/en-us.locale';
import { givingLinkDialogEnUsLocale } from 'components/post/GivingLinkDialog/translations/en-us.locale';
import { greetingsMessageEnUsLocale } from 'components/auth/GreetingsMessage/translations/en-us.locale';
import { homeFeedPostsEnUsLocale } from 'components/post/HomeFeedPosts/translations/en-us.locale';
import {
  linkAttachmentDialogEnUsLocale,
} from 'components/common/TextEditor/components/LinkAttachmentDialog/translations/en-us.locale';
import {
  linkAttachmentFormEnUsLocale,
} from 'components/common/TextEditor/components/LinkAttachmentForm/translations/en-us.locale';
import { logoEnUsLocale } from 'components/common/Logo/translations/en-us.locale';
import { manageGivingLinkDialogEnUsLocale } from 'components/profile/ManageGivingLinkDialog/translations/en-us.locale';
import { manageGivingLinkFormEnUsLocale } from 'components/profile/ManageGivingLinkForm/translations/en-us.locale';
import { myPostsEnUsLocale } from 'components/post/MyPosts/translations/en-us.locale';
import { navigationTabsEnUsLocale } from 'components/site/NavigationTabs/translations/en-us.locale';
import { postCreatedEnUsLocale } from 'components/post/PostCreated/translations/en-us.locale';
import { postCreationButtonEnUsLocale } from 'components/profile/PostCreationButton/translations/en-us.locale';
import { postFeedPreviewEnUsLocale } from 'components/post/PostFeedPreview/translations/en-us.locale';
import { postFeedReactionsEnUsLocale } from 'components/post/PostFeedReactions/translations/en-us.locale';
import { postFormEnUsLocale } from 'components/post/PostForm/translations/en-us.locale';
import { postFormMediaEnUsLocale } from 'components/post/PostFormMedia/translations/en-us.locale';
import { postMenuEnUsLocale } from 'components/post/PostMenu/translations/en-us.locale';
import { postingPreferenceEnUsLocale } from 'components/post/PostingPreference/translations/en-us.locale';
import { postingPreferencesEnUsLocale } from 'components/post/PostingPreferences/translations/en-us.locale';
import { postsTabEnUsLocale } from 'components/search/PostsTab/translations/en-us.locale';
import { profileEnUsLocale } from 'components/search/Profile/translations/en-us.locale';
import { profilesTabEnUsLocale } from 'components/search/ProfilesTab/translations/en-us.locale';
import { reactionsEnUsLocale } from 'components/post/Reactions/translations/en-us.locale';
import { resetPasswordDialogEnUsLocale } from 'components/auth/ResetPasswordDialog/translations/en-us.locale';
import { resetPasswordFormEnUsLocale } from 'components/auth/ResetPasswordForm/translations/en-us.locale';
import { resetPasswordPageEnUsLocale } from 'pages/ResetPasswordPage/translations/en-us.locale';
import { resultEnUsLocale } from 'components/search/Result/translations/en-us.locale';
import { searchFormEnUsLocale } from 'components/search/SearchForm/translations/en-us.locale';
import { sidebarEnUsLocale } from 'components/profile/Sidebar/translations/en-us.locale';
import { signInFormEnUsLocale } from 'components/auth/SignInForm/translations/en-us.locale';
import { signInPageEnUsLocale } from 'pages/SignInPage/translations/en-us.locale';
import { signUpFormEnUsLocale } from 'components/auth/SignUpForm/translations/en-us.locale';
import { signUpPageEnUsLocale } from 'pages/SignUpPage/translations/en-us.locale';
import { siteAvatarImageCropDialogEnUsLocale } from 'components/site/SiteAvatarImageCropDialog/translations/en-us.locale';
import { siteAvatarRadioGroupEnUsLocale } from 'components/site/SiteAvatarRadioGroup/translations/en-us.locale';
import { siteCreationDialogEnUsLocale } from 'components/site/SiteCreationDialog/translations/en-us.locale';
import { siteCreationFormEnUsLocale } from 'components/site/SiteCreationForm/translations/en-us.locale';
import { siteCreationPageEnUsLocale } from 'pages/SiteCreationPage/translations/en-us.locale';
import { toolbarEnUsLocale } from 'components/common/TextEditor/components/Toolbar/translations/en-us.locale';

export const locale: ResourceLanguage = {
  translations: {
    ...aboutSiteEnUsLocale,
    ...allReactionsDialogEnUsLocale,
    ...authPageEnUsLocale,
    ...authProvidersEnUsLocale,
    ...backButtonEnUsLocale,
    ...commentEnUsLocale,
    ...commentFormEnUsLocale,
    ...commentMenuEnUsLocale,
    ...commentsEnUsLocale,
    ...coverImageEnUsLocale,
    ...deletionDialogEnUsLocale,
    ...editPostPageEnUsLocale,
    ...emailVerificationDialogEnUsLocale,
    ...emailVerificationFormEnUsLocale,
    ...emailVerificationPageEnUsLocale,
    ...forgotPasswordDialogEnUsLocale,
    ...forgotPasswordFormEnUsLocale,
    ...forgotPasswordPageEnUsLocale,
    ...givingLinkButtonEnUsLocale,
    ...givingLinkDialogEnUsLocale,
    ...greetingsMessageEnUsLocale,
    ...homeFeedPostsEnUsLocale,
    ...linkAttachmentDialogEnUsLocale,
    ...linkAttachmentFormEnUsLocale,
    ...logoEnUsLocale,
    ...manageGivingLinkDialogEnUsLocale,
    ...manageGivingLinkFormEnUsLocale,
    ...myPostsEnUsLocale,
    ...navigationTabsEnUsLocale,
    ...postCreatedEnUsLocale,
    ...postCreationButtonEnUsLocale,
    ...postFeedPreviewEnUsLocale,
    ...postFeedReactionsEnUsLocale,
    ...postFormEnUsLocale,
    ...postFormMediaEnUsLocale,
    ...postingPreferenceEnUsLocale,
    ...postingPreferencesEnUsLocale,
    ...postMenuEnUsLocale,
    ...postsTabEnUsLocale,
    ...profileEnUsLocale,
    ...profilesTabEnUsLocale,
    ...reactionsEnUsLocale,
    ...resetPasswordDialogEnUsLocale,
    ...resetPasswordFormEnUsLocale,
    ...resetPasswordPageEnUsLocale,
    ...resultEnUsLocale,
    ...searchFormEnUsLocale,
    ...sidebarEnUsLocale,
    ...signInFormEnUsLocale,
    ...signInPageEnUsLocale,
    ...signUpFormEnUsLocale,
    ...signUpPageEnUsLocale,
    ...siteAvatarImageCropDialogEnUsLocale,
    ...siteAvatarRadioGroupEnUsLocale,
    ...siteCreationDialogEnUsLocale,
    ...siteCreationFormEnUsLocale,
    ...siteCreationPageEnUsLocale,
    ...toolbarEnUsLocale,
  },
};

import { APIRequest } from 'store/types';
import {
  MyPost,
  Post,
  PostState,
  ReactionData,
} from 'components/post/store/types';
import { RootState } from 'store';

export const getPostSlice = (state: RootState): PostState => state.post;
export const selectMyPostsState = (
  state: RootState,
): APIRequest<MyPost[] | null> => getPostSlice(state).myPosts;
export const selectMyPostsData = (
  state: RootState,
): MyPost[] | null => getPostSlice(state).myPosts?.data || null;
export const selectHomeFeedPostsState = (
  state: RootState,
): APIRequest<Post[] | null> => getPostSlice(state).homeFeedPosts;
export const selectHomeFeedPostsData = (
  state: RootState,
): Post[] | null => selectHomeFeedPostsState(state).data || null;
export const selectCreateEditPostState = (
  state: RootState,
): APIRequest => getPostSlice(state).createEditPost;
export const selectPostState = (
  state: RootState,
): APIRequest<Post | null> => getPostSlice(state).post;
export const selectPostData = (
  state: RootState,
): Post | null => getPostSlice(state).post?.data || null;
export const selectDeletePostState = (
  state: RootState,
): APIRequest => getPostSlice(state).deletePost;
export const selectPostReactionsState = (
  state: RootState,
): APIRequest<ReactionData[] | null> => getPostSlice(state).postReactions;
export const selectPostReactionsData = (
  state: RootState,
): ReactionData[] | null => selectPostReactionsState(state).data || null;

import { AuthProvider } from 'components/auth/AuthProvider';
import { BasicAuth } from 'components/auth/BasicAuth';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Routing } from 'Routing';
import { ScrollToTop } from 'components/common/ScrollToTop';
import { ThemeProvider } from '@mui/material';
import { store } from 'store';
import { theme } from 'theme';
import React, { FC } from 'react';

export const App: FC = () => (
  <BasicAuth>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ScrollToTop>
              <Routing />
            </ScrollToTop>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </BasicAuth>
);

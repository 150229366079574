import * as TK from 'components/auth/SignInForm/translations/constants';
import { Translations } from 'translations/types';

export const signInFormEnUsLocale: Translations = {
  [TK.SIGN_IN_FORM_EMAIL_PLACEHOLDER]: 'Email',
  [TK.SIGN_IN_FORM_PASSWORD_PLACEHOLDER]: 'Password',
  [TK.SIGN_IN_FORM_REMEMBER_ME_LABEL]: 'Remember me',
  [TK.SIGN_IN_FORM_FORGOT_PASSWORD]: 'Forgot password?',
  [TK.SIGN_IN_FORM_SUBMIT_BUTTON_LABEL]: 'Sign In',
  [TK.SIGN_IN_FORM_EMAIL_INVALID_ERROR_MESSAGE]: 'Please enter a valid email address.',
  [TK.SIGN_IN_FORM_PASSWORD_REQUIRED_ERROR_MESSAGE]: 'Please enter a password.',
  [TK.SIGN_IN_FORM_CREDENTIALS_INCORRECT_ERROR_MESSAGE]: 'The email address or password combination is incorrect.',
};

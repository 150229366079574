import { FileUploadReturnData, deleteImages, uploadMedia } from 'components/media/store/actions';
import { FormMedia } from 'components/post/PostFormMedia';
import {
  MyPost,
  Post,
  PostMedia,
  Reaction,
  ReactionData,
  ReactionType,
} from 'components/post/store/types';
import { PaginationParameters } from 'store/utils/pagination';
import { RootState } from 'store';
import { StorageBuckets } from 'components/media/enums/storage-buckets.enum';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectHomeFeedPostsData, selectMyPostsData, selectPostData } from 'components/post/store/selectors';
import { selectSiteInfo } from 'components/site/store/selectors';
import { supabase } from 'configuration/supabaseClient';

type GetPostsParameters = PaginationParameters;

const POSTS_PAGE_SIZE = 12;

export const getMyPosts = createAsyncThunk(
  'post/getMyPosts',
  async (
    { loadNextPage }: GetPostsParameters | undefined = {},
    { rejectWithValue, getState },
  ) => {
    let offset = 0;

    if (loadNextPage) {
      const posts = selectMyPostsData(getState() as RootState);
      offset = posts?.length || 0;
    }

    const { data, error } = await supabase
      .from('my_posts')
      .select()
      .eq('hidden', false)
      .order('created', { ascending: false })
      .range(offset, offset + POSTS_PAGE_SIZE - 1)
      .returns<MyPost[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

export const getHomeFeedPosts = createAsyncThunk(
  'post/getHomeFeedPosts',
  async (
    { loadNextPage }: GetPostsParameters | undefined = {},
    { rejectWithValue, getState },
  ) => {
    let offset = 0;

    if (loadNextPage) {
      const posts = selectHomeFeedPostsData(getState() as RootState);
      offset = posts?.length || 0;
    }

    const { data, error } = await supabase
      .from('all_posts')
      .select()
      .order('created', { ascending: false })
      .range(offset, offset + POSTS_PAGE_SIZE - 1)
      .returns<Post[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

const mapURLsToPostMedia = (
  data: string[],
): Partial<PostMedia>[] => data.map((url, index) => ({
  url,
  sort_order: index,
}));

export interface CreatePostParameters {
  title: string;
  body: string;
  media: File[];
  hashtags: string[];
  highlight: boolean;
  temporary: boolean;
  showGivingLink: boolean;
  pushImmediately: boolean;
}

export const createPost = createAsyncThunk(
  'post/createPost',
  async (
    values: CreatePostParameters,
    { rejectWithValue, getState },
  ) => {
    try {
      const site = selectSiteInfo(getState() as RootState);
      if (!site) {
        return rejectWithValue(null);
      }

      const uploadedMedia = await uploadMedia(values.media, site.sid);
      const mediaURLs = uploadedMedia.map(({ path }) => path);

      const { data, error } = await supabase
        .from('my_posts')
        .insert({
          sid: site.sid,
          title: values.title,
          body: values.body,
          hashtags: values.hashtags,
          highlight: values.highlight,
          temporary: values.temporary,
          show_giving: values.showGivingLink,
          push_immediately: values.pushImmediately,
          images: mapURLsToPostMedia(mediaURLs),
        });

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

const mergeMediaURLs = (
  initialMedia: FormMedia[],
  initialNewMedia: FileUploadReturnData[],
): string[] => {
  const newMedia = [...initialNewMedia];

  return initialMedia
    .reduce((
      accumulator,
      { file, url },
    ) => {
      let item = url;

      if (file) {
        const media = newMedia.shift();
        item = media?.path || '';
      }

      return [...accumulator, item];
    }, [] as string[]);
};

interface UpdatePostParameters extends Omit<CreatePostParameters, 'media'> {
  media: FormMedia[];
}

export const updatePost = createAsyncThunk(
  'post/updatePost',
  async (
    values: UpdatePostParameters,
    { rejectWithValue, getState },
  ) => {
    try {
      const site = selectSiteInfo(getState() as RootState);
      const post = selectPostData(getState() as RootState);
      if (!(site && post)) {
        return rejectWithValue(null);
      }

      const newMedia: File[] = values.media
        .filter(({ file }) => Boolean(file))
        .map(({ file }) => file!);
      const uploadedMedia = await uploadMedia(newMedia, site.sid);
      const mergedMediaURLs = mergeMediaURLs(values.media, uploadedMedia);

      const removedMedia = post.images
        .filter(({ url }) => !mergedMediaURLs.includes(url))
        .map(({ url }) => url);
      if (removedMedia.length) {
        await deleteImages(removedMedia, StorageBuckets.MEDIA);
      }

      const { data, error } = await supabase
        .from('my_posts')
        .update({
          title: values.title,
          body: values.body,
          hashtags: values.hashtags,
          highlight: values.highlight,
          temporary: values.temporary,
          show_giving: values.showGivingLink,
          push_immediately: values.pushImmediately,
          images: mapURLsToPostMedia(mergedMediaURLs),
        })
        .eq('pid', post.pid);

      if (error) {
        return rejectWithValue(error.message);
      }

      return data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const getPost = createAsyncThunk(
  'post/getPost',
  async (id: string, { rejectWithValue }) => {
    const { data, error } = await supabase
      .from('all_posts')
      .select()
      .eq('pid', id)
      .select()
      .single<Post>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);

interface ReactToPostParameters {
  sid: string;
  pid: string;
  reaction: ReactionType;
}

export const reactToPost = createAsyncThunk(
  'post/reactToPost',
  async ({ sid, pid, reaction }: ReactToPostParameters, { rejectWithValue }) => {
    const { data, error } = await supabase.rpc('react_to_post', {
      sid,
      pid,
      reaction,
    });

    if (error) {
      rejectWithValue(error.message);
    }

    return data as Reaction[];
  },
);

interface DeletePostParameters {
  pid: string;
  images: string[];
}

export const deletePost = createAsyncThunk(
  'post/deletePost',
  async ({ pid, images }: DeletePostParameters, { rejectWithValue }) => {
    try {
      if (images.length) {
        await deleteImages(images, StorageBuckets.MEDIA);
      }

      const { error } = await supabase
        .from('my_posts')
        .delete()
        .eq('pid', pid);

      if (error) {
        rejectWithValue(error.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPostAllReactions = createAsyncThunk(
  'post/allReactions',
  async (pid: string, { rejectWithValue }) => {
    const { data, error } = await supabase.rpc('post_all_reactions', { pid });

    if (error) {
      rejectWithValue(error.message);
    }

    return data as ReactionData[];
  },
);

import { AppRoutes } from 'enums/app-routes.enum';
import { DefaultSuspense } from 'components/common/DefaultSuspense';
import { ProtectedRoute } from 'components/auth/ProtectedRoute';
import { Route, Routes } from 'react-router-dom';
import React, { FC, lazy } from 'react';

const SignInPage = lazy(() => import('pages/SignInPage'));
const SignUpPage = lazy(() => import('pages/SignUpPage'));
const EmailVerificationPage = lazy(() => import('pages/EmailVerificationPage'));
const SiteCreationPage = lazy(() => import('pages/SiteCreationPage'));
const HomeFeedPage = lazy(() => import('pages/HomeFeedPage'));
const MySitePage = lazy(() => import('pages/MySitePage'));
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'));
const NewPostPage = lazy(() => import('pages/NewPostPage'));
const EditPostPage = lazy(() => import('pages/EditPostPage'));
const PostPage = lazy(() => import('pages/PostPage'));
const SearchPage = lazy(() => import('pages/SearchPage'));

export const Routing: FC = () => (
  <Routes>
    <Route
      path={AppRoutes.HOME}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <HomeFeedPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.MY_SITE}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <MySitePage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.NEW_POST}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <NewPostPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.EDIT_POST}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <EditPostPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.POST}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <PostPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.SEARCH}
      element={(
        <ProtectedRoute>
          <DefaultSuspense>
            <SearchPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.SIGN_IN}
      element={(
        <DefaultSuspense>
          <SignInPage />
        </DefaultSuspense>
      )}
    />
    <Route
      path={AppRoutes.SIGN_UP}
      element={(
        <DefaultSuspense>
          <SignUpPage />
        </DefaultSuspense>
      )}
    />
    <Route
      path={AppRoutes.EMAIL_VERIFICATION}
      element={(
        <DefaultSuspense>
          <EmailVerificationPage />
        </DefaultSuspense>
      )}
    />
    <Route
      path={AppRoutes.SITE_CREATION}
      element={(
        <ProtectedRoute skipSidCheck>
          <DefaultSuspense>
            <SiteCreationPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
    <Route
      path={AppRoutes.FORGOT_PASSWORD}
      element={(
        <DefaultSuspense>
          <ForgotPasswordPage />
        </DefaultSuspense>
      )}
    />
    <Route
      path={AppRoutes.RESET_PASSWORD}
      element={(
        <ProtectedRoute skipSidCheck>
          <DefaultSuspense>
            <ResetPasswordPage />
          </DefaultSuspense>
        </ProtectedRoute>
      )}
    />
  </Routes>
);
